html {
  scroll-behavior: smooth;
}

label {
  font-weight: bold;
}

.ant-btn-group.ant-dropdown-button {
  button {
    width: 100%;
  }
}

.table-pointer {
  .ant-table-row {
    cursor: pointer;
  }
}

.fa-primary {
  fill: white !important;
}

.ant-tag {
  font-weight: 700;
}

.modal-fullwidth {
  width: 100vw !important;

  .ant-modal-body {
    height: 85vh;
    overflow-y: auto;
    background-color: #f2f2f7;
  }

  .ant-form {
    width: 800px;
    margin: auto;

    label {
      font-size: 15px;
      font-weight: 500;
    }

    .form-section {
      box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
      margin-bottom: 2em !important;
      background-color: white;
      border-radius: 8px;
      padding: 2em 3em;
    }
  }
}

.ant-table-thead > tr > th {
  background-color: #fff !important;
}

.apps-container {
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
  border-radius: 16px;
  overflow: hidden;
}

#qr_code {
  display: none;
}

.footer-wrapper {
  background-color: #fafafa;

  p {
    font-size: 15px !important;
  }

  h3 {
    font-size: 20px;
  }

  .apps-main-footer {
    display: flex;
    align-items: center;
    height: 65px;
    padding: 3em 0 !important;
  }
}

.dark-body {
  .ant-modal-body {
    background-color: #fff;
  }
}

.ant-pagination {
  margin-top: 12px;
}

.form-section {
  box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
  margin-bottom: 2em !important;
  background-color: white;
  border-radius: 8px;
  padding: 2em 3em;
}

.filter-container {
  margin-bottom: 12px;
}

.filter-item {
  display: flex;
  flex-direction: column;

  label {
    font-weight: 600;
    margin-bottom: 0.5em;
  }

  &.btn {
    margin-top: 29px;
  }
}

.box {
  // box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
  //   rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
  padding: 1em 2em !important;
}

.ant-menu-item-icon {
  font-size: 22px;
}

#loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.avatar-form {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .avatar-uploader {
    display: flex;
    justify-content: center;
    .ant-upload {
      border-radius: 50%;
      overflow: hidden;
    }
  }

  &.square {
    .ant-upload {
      border-radius: 5px;
      overflow: hidden;
    }
  }
}

.ant-select.readOnly {
  pointer-events: none !important;
}
h3.cr-user-name.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.app-main-sidebar.ant-layout-sider {
  width: 14.5rem !important;
}

.container-full-height {
  height: calc(100vh - 120px);
  padding-bottom: 2em;
  overflow: auto;
}

.dashboard-container {
  .title-h2 {
    font-size: 23px;
  }
}

.addressListItem {
  transition: 0.4s;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: #609aff, $alpha: 0.1);
  }

  &.active {
    background-color: rgba($color: #609aff, $alpha: 0.2);
  }
}

.shipping-form {
  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-form-item-label {
    padding: 0;
  }
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 0 10px 10px 10px;
  border-radius: 7px;
}

.glass {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.footer {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 830px) {
  .ant-result {
    padding: 0;
  }
  .app-main-sidebar.ant-layout-sider {
    width: unset !important;
  }

  .footer {
    padding-top: 40px;
    padding-bottom: 40px;

    .ant-col {
      margin-bottom: 1em !important;
    }
  }

  .ant-menu-submenu-title {
    padding-left: 5px !important;
  }

  .form-item {
    margin-right: 0 !important;
  }
}
