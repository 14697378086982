/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.app-layout-userHeader {
  min-height: 100vh;
  position: relative;
  background-color: transparent;
  overflow-x: hidden;
}
.app-layout-userHeader.appMainFixedFooter {
  padding-bottom: 46px;
}
.app-layout-userHeader.appMainFixedFooter .app-main-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;
}
.ant-layout-sider-zero-width-trigger {
  display: none;
}
.app-userHeader-sidebar.ant-layout-sider {
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 0 !important;
  width: 17.5rem !important;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .app-userHeader-sidebar.ant-layout-sider {
    width: 15rem !important;
  }
}
.app-userHeader-sidebar .ant-layout-sider-trigger {
  display: none;
}
.app-userHeader-sidebar.ant-layout-sider-has-trigger {
  padding-bottom: 0;
}
.app-userHeader-sidebar.ant-layout-sider-collapsed {
  width: 0 !important;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
@media screen and (min-width: 992px) {
  .app-userHeader-sidebar.ant-layout-sider-collapsed {
    width: 4rem !important;
  }
}
.app-userHeader-sidebar.ant-layout-sider-collapsed + .app-layout-userHeader-main {
  width: 100% !important;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
@media screen and (min-width: 992px) {
  .app-userHeader-sidebar.ant-layout-sider-collapsed + .app-layout-userHeader-main {
    width: calc(100% - 4rem) !important;
  }
}
.app-userHeader-sidebar.ant-layout-sider-collapsed .cr-user-info {
  padding-left: 12px;
  padding-right: 12px;
}
.app-userHeader-sidebar.ant-layout-sider-collapsed .cr-user-info-content {
  opacity: 0;
  visibility: hidden;
  width: 0;
  padding-left: 0;
  padding-right: 0;
}
.app-userHeader-sidebar.ant-layout-sider-collapsed .ant-menu-item-group-title {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  padding: 0;
}
.app-userHeader {
  padding-left: 20px;
  padding-right: 20px;
  color: #111827;
  background-color: #fff;
  height: 56px;
  line-height: 1;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .app-userHeader {
    padding-left: 30px;
    padding-right: 30px;
    height: 71px;
  }
}
.app-userHeader .trigger {
  font-size: 20px;
  margin-right: 20px;
  padding: 5.5px;
  color: #111827;
}
.app-userHeader .trigger svg {
  display: block;
}
[dir=rtl] .app-userHeader .trigger {
  margin-right: 0;
  margin-left: 20px;
}
@media screen and (min-width: 1200px) {
  .app-userHeader .trigger {
    display: none;
  }
}
.app-userHeader .cr-user-info {
  background-color: transparent;
}
@media screen and (max-width: 576px) {
  .app-userHeader .cr-user-info {
    padding-left: 5px;
  }
  [dir=rtl] .app-userHeader .cr-user-info {
    padding-left: 0;
    padding-right: 5px;
  }
}
@media screen and (min-width: 768px) {
  .app-userHeader .cr-user-info {
    padding-right: 0;
  }
  [dir=rtl] .app-userHeader .cr-user-info {
    padding-left: 0;
    padding-right: 16px;
  }
}
.app-userHeader .cr-user-info-content {
  display: none;
}
.app-userHeader-search {
  position: relative;
  max-width: 140px;
  min-height: 36px;
  margin-left: auto;
  margin-right: 10px;
}
[dir=rtl] .app-userHeader-search {
  margin-left: 10px;
  margin-right: auto;
}
@media screen and (min-width: 576px) {
  .app-userHeader-search {
    margin-right: 20px;
    max-width: 165px;
  }
  [dir=rtl] .app-userHeader-search {
    margin-right: auto;
    margin-left: 20px;
  }
}
.app-userHeader-search .ant-input-wrapper {
  top: 50%;
  right: 0;
  z-index: 1;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
[dir=rtl] .app-userHeader-search .ant-input-wrapper {
  right: auto;
  left: 0;
}
.app-userHeader-search .ant-input {
  padding: 8px 14px;
  height: 36px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 104px;
}
.app-userHeader-search .ant-input:focus {
  width: 135px;
}
@media screen and (min-width: 576px) {
  .app-userHeader-search .ant-input {
    width: 129px;
  }
  .app-userHeader-search .ant-input:focus {
    width: 165px;
  }
}
.app-userHeader-search .ant-input-search-button {
  height: 36px;
  width: 36px;
  box-shadow: none;
}
.app-userHeader-search .ant-input-search-button .anticon svg {
  display: block;
}
.app-userHeader-search.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 5px 0 0 5px;
}
.app-userHeader-sectionDesktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .app-userHeader-sectionDesktop {
    display: flex;
    align-items: center;
  }
}
.app-userHeader-section-mobile {
  display: block;
}
@media screen and (min-width: 768px) {
  .app-userHeader-section-mobile {
    display: none;
  }
}
.app-userHeader-section-mobile .ant-dropdown-link {
  font-size: 24px;
  color: #111827;
}
.app-userHeader-section-mobile .ant-dropdown-link svg {
  display: block;
}
.app-layout-userHeader-main {
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  width: 100% !important;
  position: relative;
  flex-shrink: 0;
}
@media screen and (min-width: 1200px) {
  .app-layout-userHeader-main {
    flex-shrink: inherit;
    width: calc(100% - 17.5rem) !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .app-layout-userHeader-main {
    width: calc(100% - 15rem) !important;
  }
}
.userHeader-main-scrollbar {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  max-height: calc(100vh - 56px);
}
.appMainFixedFooter .userHeader-main-scrollbar {
  max-height: calc(100vh - 104px);
}
@media screen and (min-width: 768px) {
  .userHeader-main-scrollbar {
    max-height: calc(100vh - 71px);
  }
  .appMainFixedFooter .userHeader-main-scrollbar {
    max-height: calc(100vh - 118px);
  }
}
.app-userHeader-sidebar-scrollbar {
  max-height: 100vh;
}
.appMainFixedFooter .app-userHeader-sidebar-scrollbar {
  max-height: calc(100vh - 46px);
}
.userHeader-sidebar-img-background {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.userHeader-sidebar-img-background:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  z-index: 1;
  opacity: 0.5;
}
.userHeader-sidebar-img-background > div {
  position: relative;
  z-index: 3;
}
.userHeader-sidebar-img-background .ant-menu.ant-menu-dark,
.userHeader-sidebar-img-background .ant-menu-dark .ant-menu-sub,
.userHeader-sidebar-img-background .ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: transparent;
  color: inherit;
}
.userHeader-sidebar-img-background .app-main-sidebar-menu.ant-menu-dark .ant-menu-item-group-title,
.userHeader-sidebar-img-background .app-main-sidebar-menu .ant-menu-item a {
  color: inherit;
}
