/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.user-pages {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.user-pages > div {
  flex: 1;
}
.user-container {
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
@media screen and (min-width: 1600px) {
  .user-container {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
.user-card {
  max-width: 480px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.user-card .ant-card-body {
  padding: 24px;
}
@media screen and (min-width: 992px) {
  .user-card .ant-card-body {
    padding: 32px;
  }
}
@media screen and (min-width: 1600px) {
  .user-card .ant-card-body {
    padding: 48px 64px;
  }
}
.user-card .ant-row > .ant-col {
  margin-bottom: 0;
}
.user-card-lg {
  max-width: 900px;
}
.user-card-lg-space .ant-card-body {
  padding: 32px;
}
@media screen and (min-width: 992px) {
  .user-card-lg-space .ant-card-body {
    padding: 48px;
  }
}
@media screen and (min-width: 1600px) {
  .user-card-lg-space .ant-card-body {
    padding: 48px 64px;
  }
}
@media screen and (min-width: 576px) {
  .user-card-unlock .ant-card-body {
    padding: 48px;
  }
}
@media screen and (min-width: 992px) {
  .user-card-unlock .ant-card-body {
    padding-left: 32px;
    padding-right: 64px;
  }
  [dir=rtl] .user-card-unlock .ant-card-body {
    padding-left: 64px;
    padding-right: 32px;
  }
}
@media screen and (min-width: 1600px) {
  .user-card-unlock .ant-card-body {
    padding-left: 80px;
    padding-right: 80px;
  }
}
.user-card-unlock .user-styled-img {
  padding-right: 0;
}
.user-card-unlock .user-styled-img svg {
  fill: #3973b9;
}
[dir=rtl] .user-card-unlock .user-styled-img {
  padding-left: 0;
}
.user-styled-img {
  height: 400px;
  width: 100%;
  display: inline-block;
  object-fit: contain;
}
.user-styled-img svg {
  fill: #3973b9;
}
@media screen and (min-width: 992px) {
  .user-styled-img {
    padding-right: 40px;
  }
  [dir=rtl] .user-styled-img {
    padding-right: 0;
    padding-left: 40px;
  }
}
.user-styled-img.mln {
  margin-left: -32px;
}
[dir=rtl] .user-styled-img.mln {
  margin-left: 0;
  margin-right: -32px;
}
.user-styled-img-auto {
  height: auto;
}
.user-card-header {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1600px) {
  .user-card-header {
    margin-bottom: 32px;
  }
}
.user-card-header h3 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 20px;
}
.user-card-logo {
  margin-right: 8px;
}
[dir=rtl] .user-card-logo {
  margin-right: 0;
  margin-left: 8px;
}
.user-card-logo img {
  height: 24px;
}
.user-form {
  text-align: left;
  margin-bottom: 12px;
}
[dir=rtl] .user-form {
  text-align: right;
}
@media screen and (min-width: 1600px) {
  .user-form {
    margin-bottom: 20px;
  }
}
.user-form .form-field {
  margin-bottom: 20px;
}
.user-field-action {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 576px) {
  .user-field-action {
    flex-direction: row;
    align-items: center;
    margin-bottom: 26px;
  }
}
@media screen and (min-width: 1600px) {
  .user-field-action {
    margin-bottom: 32px;
  }
}
.user-field-action .ant-form-item-control-input {
  min-height: 10px;
}
.user-field-action .ant-form-item-control-input-content {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 576px) {
  .user-field-action .ant-form-item-control-input-content {
    flex-direction: row;
    align-items: center;
  }
}
.user-field-action-row .ant-form-item-control-input-content {
  flex-direction: row;
  align-items: center;
}
.user-field-action-row .user-field-action-link {
  padding-top: 2px;
}
.user-field-action-link {
  color: #3973b9;
  font-size: 12px;
  cursor: pointer;
}
.user-field-action-link.ml-auto {
  margin-top: 8px;
}
@media screen and (min-width: 576px) {
  .user-field-action-link.ml-auto {
    margin-left: auto;
    margin-top: 0;
  }
  [dir=rtl] .user-field-action-link.ml-auto {
    margin-left: 0;
    margin-right: auto;
  }
}
.user-form-btn {
  width: 100%;
}
.user-card-footer-action {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}
@media screen and (min-width: 1600px) {
  .user-card-footer-action {
    margin-bottom: 16px;
  }
}
.user-card-footer-action > span {
  color: #757575;
  font-size: 12px;
  margin-right: 16px;
}
[dir=rtl] .user-card-footer-action > span {
  margin-right: 0;
  margin-left: 16px;
}
.user-social-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 576px) {
  .user-social-link {
    justify-content: flex-start;
  }
}
.user-social-link button {
  background-color: transparent;
  box-shadow: none;
  border: 0 none;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  min-width: 36px;
  height: 36px;
}
.user-social-link button:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.user-social-link button:focus {
  background-color: rgba(0, 0, 0, 0.15);
}
.user-social-link button:after {
  box-shadow: none;
}
.user-social-link button .anticon {
  font-size: 12px;
  display: block;
}
@media screen and (min-width: 768px) {
  .user-social-link button .anticon {
    font-size: 14px;
  }
}
.user-social-link button .anticon svg {
  display: block;
}
.user-card-footer {
  color: #616161;
  font-size: 12px;
}
.user-card-footer-link {
  margin-left: 8px;
  color: #3973b9;
  cursor: pointer;
  display: inline-block;
}
[dir=rtl] .user-card-footer-link {
  margin-left: 0;
  margin-right: 8px;
}
.user-card-para {
  margin-bottom: 24px;
}
@media screen and (min-width: 1600px) {
  .user-card-para {
    margin-bottom: 48px;
  }
}
.user-card-for-password .ant-card-body {
  padding: 0;
}
.user-card-for-password .ant-card-body > .ant-row {
  margin: 0 !important;
}
.user-card-for-password .ant-card-body > .ant-row > .ant-col {
  padding: 0 !important;
}
.user-card-for-password .user-styled-img {
  padding-right: 0;
  height: 100%;
  object-fit: cover;
}
[dir=rtl] .user-card-for-password .user-styled-img {
  padding-left: 0;
}
.user-styled-for-password {
  padding: 32px;
}
@media screen and (min-width: 992px) {
  .user-styled-for-password {
    padding: 48px;
  }
}
@media screen and (min-width: 1600px) {
  .user-styled-for-password {
    padding: 48px 64px;
  }
}
.user-styled-reset-img-col {
  text-align: center;
}
@media screen and (min-width: 992px) {
  .user-styled-reset-img-col {
    align-self: center;
  }
}
