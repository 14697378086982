$base-color: #0082c5;

.text-blue {
  color: #619bff;
}

.ant-select.readOnly {
  pointer-events: none;
}

.ant-btn-primary {
  background: #3c83fc;

  color: #fff;
  border: none;
  transition: 0.4s;

  font-weight: 500;

  &:hover {
    color: #fff;
    background: #619bff;
  }
}

.info-row {
  .ant-col {
    // margin-bottom: 0;
  }
  .ant-col:first-child {
    font-weight: bold;
  }
}

.page-header {
  background-color: #e3e4e8;
  padding: 10px;
  align-items: center;
  margin-bottom: 1em;
}

/* fix header content page */
.ant-page-header {
  background: #f2f7f7;
  position: sticky;
  width: 100%;
  z-index: 99;
  top: 0;
}

.main-content-view {
  padding: 0;
}

.b-container {
  padding: 0 24px;
}

.ant-page-header {
  border-bottom: 1px solid #efefef;
  margin-bottom: 2em;
  background-color: #ededed;

  .ant-page-header-heading-extra {
    display: flex;
  }
}

.ant-upload {
  img {
    object-fit: cover;
  }
}

//Custom theme
.app-layout-main {
  width: 100% !important;
}

.ant-btn-background-ghost.ant-btn-primary {
  border: 1px solid;
  box-shadow: none;

  &:hover {
    background-color: #3c83fc;
    color: #fff;
  }
}
.tooltip-custom {
  .ant-popover-content {
    width: 400px;
  }
}

.tooltip-text {
  text-decoration: underline;
  text-decoration-style: dotted;
  color: #3c83fc;
  cursor: pointer;
}

.ant-menu-item-group-title {
  &:hover {
    background-color: #f1f1f1;
  }
}

.ant-table-row {
  .ant-table-cell:hover {
    cursor: pointer;

    .inputFake {
      display: inline-block;
      border-radius: 4px;
      width: 100%;
      height: 30px;
      padding: 4px 10px;
      background-color: #fff !important;
    }
  }
  .show-on-hover {
    transition: 0.4s;
    opacity: 0;
  }
}

.disable-edit-mode {
  .show-on-hover {
    display: none;
  }
}

.ant-alert {
  padding: 4px 8px;
}

.inputFake {
  display: inline-block;
  border-radius: 6px;
  width: 100%;
  height: 30px;
  padding: 4px 10px;
  background-color: transparent !important;
  // border: 1px solid #d4d7db !important;
  background-color: transparent;
  border-color: transparent;
  transition: 0.3s;
}

.ant-table-cell-row-hover {
  background-color: rgba($color: #619bff, $alpha: 0.2) !important;

  .show-on-hover {
    opacity: 1;
  }

  .inputFake {
    background-color: #fff !important;
  }
}

.input-align-right {
  .ant-input-number-handler-wrap {
    display: none;
  }
  input {
    text-align: right;
    padding-right: 15px;
  }
}

.description-custom {
  .ant-descriptions-item-content {
    font-weight: bold;
  }
}

.ant-dropdown {
  background-color: #fff !important;
  border: 1px solid #efefef;
  border-radius: 5px;
}

.cr-user-info {
  padding-right: 0;
}

.text-center {
  text-align: center;
}

.otp-input {
  input {
    border: 1px solid #999;
    margin: 10px;
    text-align: center;
    width: 40px !important;
    height: 45px !important;
    font-size: 25px;
    border-radius: 5px;
  }
}

.custom-icon {
  font-size: 17px !important;
  transform: translateY(2px) !important;

  &.fs-18 {
    font-size: 18px !important;
  }
}

label {
  width: 100% !important;
}

.user-profile-dropdown {
  .ant-list-item {
    padding: 0;
  }
}

.ant-image {
  border-radius: 5px;
  overflow: hidden;
}

.ant-table-row.active td {
  background-color: #dfebff !important;
}

.ant-table-cell {
  .ant-empty {
    margin: 5px 0;
  }
}

.custom-menu {
  .ant-menu-submenu {
    padding: 10px !important;
    line-height: 200%;

    &::after {
      bottom: 3px !important;
    }
  }

  &.vertical {
    .ant-menu-item.ant-menu-item-only-child {
      display: flex;
      align-items: center;
    }
  }
}

.ant-menu-item-group-title {
  padding: 0 !important;
}

.ant-menu-submenu-popup {
  top: 65px !important;
}

.ant-card-bordered {
  border: 1px solid #dadbdb;
  border-radius: 5px;
}

.breadcrumb {
  background-color: #ededed;
  margin-bottom: 0;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.table-striped-rows tr:nth-child(2n) td {
  background-color: #fbfbfb;
}
.table-striped-rows thead {
  background-color: #f1f1f1;
}

.ant-select-item-option-disabled {
  .ant-tag {
    filter: grayscale(1);
  }
}

.order-history-content {
  width: calc(100% - 320px);
  // margin-left: 320px;
  padding-right: 10px;
}
.order-history-sidebar {
  width: 320px;
  padding: 0 10px;
}

@media screen and (max-width: 900px) {
  .order-history-content {
    width: 100%;
    margin-left: 0;
    padding: 10px;
  }

  .order-history-sidebar {
    display: none;
  }

  .b-container {
    padding: 0 14px;
  }

  .custom-menu {
    .ant-menu-item {
      margin: 0 !important;
    }

    .ant-menu-submenu {
      padding: 0 !important;

      .ant-menu-submenu-title {
        padding: 0 16px !important;
      }
    }
  }

  .custom-icon.fs-18 {
    transform: translateY(4px) !important;
  }

  .ant-menu-submenu-popup {
    top: 72px !important;
    right: 250px;
    left: unset !important;
  }
}
