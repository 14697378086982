/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop {
  padding-top: 0;
  padding-bottom: 0;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-vertical .ant-menu-item,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-vertical .ant-menu-item,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-vertical-left .ant-menu-item,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-vertical-left .ant-menu-item,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-vertical-right .ant-menu-item,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-vertical-right .ant-menu-item,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-inline .ant-menu-item,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-inline .ant-menu-item,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-inline .ant-menu-submenu-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-inline-collapsed,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-inline-collapsed {
  width: auto;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-item-group-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-item-group-title {
  padding-left: 24px;
  padding-right: 24px;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-submenu .ant-menu-submenu-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-submenu .ant-menu-submenu-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-submenu-title > .ant-menu-title-content,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-submenu-title > .ant-menu-title-content,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-item .ant-menu-title-content > a,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-item .ant-menu-title-content > a {
  display: flex;
  align-items: center;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu .ant-menu-sub .ant-menu-vertical,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu .ant-menu-sub .ant-menu-vertical {
  background: inherit;
  color: inherit;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-submenu .ant-menu-submenu-vertical .ant-menu-submenu-open .ant-menu-submenu-active,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-submenu .ant-menu-submenu-vertical .ant-menu-submenu-open .ant-menu-submenu-active,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-submenu .ant-menu-submenu-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-submenu .ant-menu-submenu-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-submenu-title > .ant-menu-title-content,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-submenu-title > .ant-menu-title-content {
  color: inherit;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-overflow-item .ant-menu-submenu .ant-menu-submenu-horizontal .ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-submenu .ant-menu-submenu-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-overflow-item .ant-menu-submenu .ant-menu-submenu-horizontal .ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-submenu .ant-menu-submenu-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-overflow-item .ant-menu-submenu .ant-menu-submenu-horizontal .ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-submenu .ant-menu-submenu-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-overflow-item .ant-menu-submenu .ant-menu-submenu-horizontal .ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-submenu .ant-menu-submenu-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-submenu-title > .ant-menu-title-content,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-submenu-title > .ant-menu-title-content,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-item .ant-menu-title-content > a,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-item .ant-menu-title-content > a {
  color: inherit;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow {
  color: inherit;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-item-icon svg,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-item-icon svg {
  display: block;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 52px !important;
}
[dir=rtl] .ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-sub.ant-menu-inline > .ant-menu-item,
[dir=rtl] .ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-sub.ant-menu-inline > .ant-menu-item,
[dir=rtl] .ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
[dir=rtl] .ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 16px !important;
  padding-right: 52px !important;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-item .anticon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-item .anticon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-submenu-title .anticon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-submenu-title .anticon {
  font-size: 20px;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-rtl .ant-menu-submenu-title .anticon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: 0;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu.ant-menu-dark,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu.ant-menu-dark,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-dark .ant-menu-sub,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-dark .ant-menu-sub,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu.ant-menu-dark .ant-menu-sub,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu.ant-menu-dark .ant-menu-sub {
  color: inherit;
  background: none;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-dark .ant-menu-item,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-dark .ant-menu-item,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-dark .ant-menu-item > a,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-dark .ant-menu-item > a,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-dark .ant-menu-item > span > a,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-dark .ant-menu-item > span > a {
  color: inherit;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-dark .ant-menu-item-selected .anticon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-dark .ant-menu-item-selected .anticon,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-dark .ant-menu-item-selected .anticon + span,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: inherit;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft .ant-menu-item a,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-item a,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-light .ant-menu-item:hover,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-light .ant-menu-item:hover,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-light .ant-menu-item-active,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-light .ant-menu-item-active,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-light .ant-menu-submenu-active,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-light .ant-menu-submenu-active,
.ant-menu-submenu-popup.ant-menu-submenu-placement-bottomLeft.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop.ant-menu-light .ant-menu-submenu-title:hover {
  color: inherit;
}
.menu-rounded .ant-menu-item a {
  color: inherit;
}
.menu-rounded .ant-menu-item a:hover,
.menu-rounded .ant-menu-item a:focus {
  color: inherit;
}
.menu-rounded .ant-menu-item:hover a:hover,
.menu-rounded .ant-menu-item:hover a:focus {
  color: inherit;
}
.menu-rounded .ant-menu-item-active,
.menu-rounded .ant-menu-submenu-title:hover {
  color: inherit;
  background-color: inherit;
}
.menu-rounded .ant-menu-submenu-open > .ant-menu-submenu-title {
  color: inherit;
}
.menu-rounded.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.menu-rounded.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.menu-rounded.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 1;
  color: inherit;
}
.menu-rounded .ant-menu-sub.ant-menu-inline {
  background: none;
}
.menu-rounded .ant-menu-item-group-list .ant-menu-item,
.menu-rounded .ant-menu-item-group-list .ant-menu-submenu-title,
.menu-rounded .ant-menu.ant-menu-inline .ant-menu-item {
  width: calc(100% - 16px);
  margin-right: 16px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
[dir=rtl] .menu-rounded .ant-menu-item-group-list .ant-menu-item,
[dir=rtl] .menu-rounded .ant-menu-item-group-list .ant-menu-submenu-title,
[dir=rtl] .menu-rounded .ant-menu.ant-menu-inline .ant-menu-item {
  margin-right: 0;
  margin-left: 16px;
  border-radius: 30px 0 0 30px;
}
.menu-rounded.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: inherit;
}
.menu-rounded.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
  color: inherit;
}
.menu-rounded.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a:hover,
.menu-rounded.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a:focus {
  color: inherit;
}
.menu-rounded .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.menu-rounded .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: inherit;
}
.menu-rounded .ant-menu-submenu-selected {
  color: inherit;
}
.menu-rounded.ant-menu-vertical .ant-menu-item::after,
.menu-rounded.ant-menu-vertical-left .ant-menu-item::after,
.menu-rounded.ant-menu-vertical-right .ant-menu-item::after,
.menu-rounded.ant-menu-inline .ant-menu-item::after {
  display: none;
}
.menu-rounded .ant-menu-item-group-title {
  padding: 8px 24px;
  color: inherit;
}
.menu-rounded .ant-menu-submenu-arrow {
  color: inherit;
}
.menu-rounded .ant-menu-submenu-arrow:before,
.menu-rounded .ant-menu-submenu-arrow:after {
  color: inherit;
  width: 8px;
  height: 1.8px;
}
.menu-rounded .ant-menu-submenu-title {
  display: flex;
  align-items: center;
}
.menu-rounded .ant-menu-submenu-title .ant-menu-item-icon svg,
.menu-rounded .ant-menu-submenu-title .anticon {
  display: block;
}
[dir=rtl] .menu-rounded .ant-menu-submenu-title .ant-menu-item-icon {
  margin-right: 0;
}
.menu-rounded.ant-menu-dark .ant-menu-item a {
  color: inherit;
}
.menu-rounded.ant-menu-dark .ant-menu-item a:hover,
.menu-rounded.ant-menu-dark .ant-menu-item a:focus {
  color: inherit;
}
.menu-rounded.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: none;
}
.menu-rounded.ant-menu-dark .ant-menu-item:hover a:hover,
.menu-rounded.ant-menu-dark .ant-menu-item-active a:hover,
.menu-rounded.ant-menu-dark .ant-menu-item:hover a:focus,
.menu-rounded.ant-menu-dark .ant-menu-item-active a:focus {
  color: inherit;
}
.menu-rounded.ant-menu-dark .ant-menu-item-group-title {
  color: inherit;
}
.menu-rounded.ant-menu-dark .ant-menu-item-active,
.menu-rounded.ant-menu-dark .ant-menu-submenu-title:hover {
  color: inherit;
  background-color: transparent;
}
.menu-rounded.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title {
  color: inherit;
}
.menu-rounded.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.menu-rounded.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: inherit;
  color: inherit;
}
.menu-rounded.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.menu-rounded.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.menu-rounded.ant-menu-dark .ant-menu-submenu > .ant-menu-submenu-title:hover > .ant-menu-submenu-expand-icon,
.menu-rounded.ant-menu-dark .ant-menu-submenu > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  color: inherit;
}
.menu-rounded.ant-menu-dark .ant-menu-submenu-selected {
  color: inherit;
}
.menu-rounded.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.menu-rounded.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: inherit;
  color: inherit;
}
.menu-rounded.ant-menu-inline-collapsed {
  width: 100% !important;
}
.menu-rounded.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
.menu-rounded.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title,
.menu-rounded.ant-menu-inline-collapsed .ant-menu.ant-menu-inline .ant-menu-item {
  width: 100%;
  margin-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
[dir=rtl] .menu-rounded.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
[dir=rtl] .menu-rounded.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title,
[dir=rtl] .menu-rounded.ant-menu-inline-collapsed .ant-menu.ant-menu-inline .ant-menu-item {
  margin-left: 0;
  border-radius: 0;
}
.menu-rounded.ant-menu-inline-collapsed .ant-menu-item-active,
.menu-rounded.ant-menu-inline-collapsed .ant-menu-submenu-title:hover {
  color: inherit;
  background-color: transparent;
}
.menu-rounded.ant-menu-inline-collapsed.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.menu-rounded.ant-menu-inline-collapsed.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
  color: inherit;
}
.menu-rounded.ant-menu-inline-collapsed.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a:hover,
.menu-rounded.ant-menu-inline-collapsed.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a:focus {
  color: inherit;
}
.menu-rounded.ant-menu-inline-collapsed .ant-menu-submenu-selected {
  color: inherit;
}
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-item-active,
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-submenu-title:hover {
  color: inherit;
}
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-submenu-selected {
  color: inherit;
}
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: inherit;
  color: inherit;
}
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow:after:hover,
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow:before:hover,
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow:after:focus,
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow:before:focus {
  color: inherit;
}
.rounded-menu-reverse .ant-menu-item-group-list .ant-menu-item,
.rounded-menu-reverse .ant-menu-item-group-list .ant-menu-submenu-title,
.rounded-menu-reverse .ant-menu.ant-menu-inline .ant-menu-item {
  margin-right: 0;
  margin-left: 16px;
  border-radius: 30px 0 0 30px;
}
[dir=rtl] .rounded-menu-reverse .ant-menu-item-group-list .ant-menu-item,
[dir=rtl] .rounded-menu-reverse .ant-menu-item-group-list .ant-menu-submenu-title,
[dir=rtl] .rounded-menu-reverse .ant-menu.ant-menu-inline .ant-menu-item {
  margin-right: 16px;
  margin-left: 0;
  border-radius: 0 30px 30px 0;
}
.rounded-menu-reverse.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
.rounded-menu-reverse.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title,
.rounded-menu-reverse.ant-menu-inline-collapsed .ant-menu.ant-menu-inline .ant-menu-item {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  border-radius: 0;
}
.standard-menu .ant-menu-item-group-list .ant-menu-item,
.standard-menu .ant-menu-item-group-list .ant-menu-submenu-title,
.standard-menu .ant-menu.ant-menu-inline .ant-menu-item {
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 4px;
}
.standard-menu.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
.standard-menu.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title,
.standard-menu.ant-menu-inline-collapsed .ant-menu.ant-menu-inline .ant-menu-item {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  border-radius: 0;
}
.bg-color-menu.ant-menu.ant-menu-dark,
.bg-color-menu.ant-menu-dark .ant-menu-sub,
.bg-color-menu.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: transparent;
}
