/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.order-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.App {
  min-height: 100vh;
}
.order-header-input-view {
  max-width: 120px;
}
@media screen and (min-width: 576px) {
  .order-header-input-view {
    max-width: 150px;
  }
}
.order-header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
}
[dir="rtl"] .order-header-right {
  padding-left: 0;
  padding-right: 10px;
}
.order-header-pagination {
  display: none;
  padding-left: 12px;
  padding-right: 2px;
}
[dir="rtl"] .order-header-pagination {
  padding-left: 2px;
  padding-right: 12px;
}
@media screen and (min-width: 768px) {
  .order-header-pagination {
    display: block;
  }
}
.order-footer-pagination {
  display: block;
  padding: 10px;
}
@media screen and (min-width: 768px) {
  .order-footer-pagination {
    display: none;
  }
}
.order-table .ant-table table {
  table-layout: auto !important;
}
.order-table .ant-table-thead > tr > th {
  font-size: 13px;
  padding: 8px;
  font-weight: 600;
  background-color: transparent;
}
.order-table .ant-table-thead > tr > th:first-child {
  padding-left: 20px;
}
[dir="rtl"] .order-table .ant-table-thead > tr > th:first-child {
  padding-left: 0;
  padding-right: 20px;
}
.order-table .ant-table-thead > tr > th:last-child {
  padding-right: 20px;
}
[dir="rtl"] .order-table .ant-table-thead > tr > th:last-child {
  padding-right: 0;
  padding-left: 20px;
}
.order-table .ant-table-thead > tr > th.order-table-action {
  text-align: center;
  background-color: #fff !important;
}
.order-table .ant-table-tbody > tr > td {
  font-size: 13px;
  padding: 12px 8px;
}
.order-table .ant-table-tbody > tr > td:first-child {
  padding-left: 20px;
}
[dir="rtl"] .order-table .ant-table-tbody > tr > td:first-child {
  padding-left: 0;
  padding-right: 20px;
}
.order-table .ant-table-tbody > tr > td:last-child {
  padding-right: 20px;
}
[dir="rtl"] .order-table .ant-table-tbody > tr > td:last-child {
  padding-right: 0;
  padding-left: 20px;
}
.order-table .ant-table-tbody > tr > td.order-table-action {
  text-align: center;
  background-color: #fff !important;
}
.order-table .badge {
  padding: 3px 10px;
  border-radius: 16px;
  display: inline-block;
}
.order-id {
  text-decoration: underline;
  color: #3973b9;
}
