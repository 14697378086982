/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.app-selected-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}
.app-selected-icon.isCenter {
  right: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.app-selected-icon svg {
  font-size: 12px;
}
