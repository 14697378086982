/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.menu-color-cell {
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
.menu-color-cell-header {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
}
.menu-color-cell-header-avatar {
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.menu-color-cell-header-content {
  width: 100%;
  padding-left: 16px;
}
.menu-color-cell-header-content-line {
  width: 50px;
  height: 4px;
}
.menu-color-cell-header-content-line + .menu-color-cell-header-content-line {
  margin-top: 8px;
}
.menu-color-cell-content {
  max-width: 200px;
  position: relative;
}
.menu-color-cell-menu-item {
  width: 100%;
  min-height: 40px;
  padding: 10px 18px;
  white-space: nowrap;
  font-size: 12px;
}
@media screen and (min-width: 1600px) {
  .menu-color-cell-menu-item {
    font-size: 12px;
  }
}
.menu-color-cell-menu-item-selected {
  padding: 10px;
  border-radius: 5px;
  width: calc(100% - 16px);
  position: relative;
  transition: all 0.4s ease;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 12px;
}
@media screen and (min-width: 1600px) {
  .menu-color-cell-menu-item-selected {
    font-size: 12px;
  }
}
.menu-color-cell-menu-item-selected.rounded-menu {
  margin-left: 0;
  margin-right: 8px;
  width: calc(100% - 8px);
  padding-left: 18px;
  padding-right: 10px;
  border-radius: 0 30px 30px 0;
}
[dir=rtl] .menu-color-cell-menu-item-selected.rounded-menu {
  margin-right: 0;
  margin-left: 8px;
  padding-left: 10px;
  padding-right: 18px;
  border-radius: 30px 0 0 30px;
}
.menu-color-cell-menu-item-selected.standard-menu {
  margin-left: 8px;
  margin-right: 8px;
  width: calc(100% - 16px);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
}
.menu-color-cell-menu-item-selected.default-menu {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  padding-left: 18px;
  padding-right: 10px;
  border-radius: 0;
}
[dir=rtl] .menu-color-cell-menu-item-selected.default-menu {
  padding-left: 10px;
  padding-right: 18px;
}
.menu-color-cell-menu-item-selected.rounded-menu-reverse {
  margin-left: 8px;
  margin-right: 0;
  width: calc(100% - 8px);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 30px 0 0 30px;
}
[dir=rtl] .menu-color-cell-menu-item-selected.rounded-menu-reverse {
  margin-right: 8px;
  margin-left: 0;
  border-radius: 0 30px 30px 0;
}
.menu-color-cell-menu-item-selected.curved-menu {
  width: calc(100% - 8px);
  margin-right: 0;
  margin-left: 8px;
  border-radius: 30px 0 0 30px;
  position: relative;
  overflow: inherit;
}
[dir=rtl] .menu-color-cell-menu-item-selected.curved-menu {
  margin-right: 8px;
  margin-left: 0;
  border-radius: 0 30px 30px 0;
}
.menu-color-cell-menu-item-selected.curved-menu:before,
.menu-color-cell-menu-item-selected.curved-menu:after {
  content: "";
  position: absolute;
  right: 0;
  z-index: 0;
  height: 30px;
  width: 30px;
  background-color: transparent;
  border-radius: 50%;
  opacity: 0;
  display: block;
  border-right: 0 none;
}
.menu-color-cell-menu-item-selected.curved-menu:before {
  top: -30px;
  box-shadow: 20px 20px 0 10px transparent;
}
.menu-color-cell-menu-item-selected.curved-menu:after {
  bottom: -30px;
  box-shadow: 20px -20px 0 10px transparent;
  top: auto;
}
.menu-color-cell-menu-item-selected.curved-menu.menu-color-cell-menu-item-selected:before {
  box-shadow: 20px 20px 0 10px #F4F7FE;
  opacity: 1;
}
.menu-color-cell-menu-item-selected.curved-menu.menu-color-cell-menu-item-selected:after {
  box-shadow: 20px -20px 0 10px #F4F7FE;
  opacity: 1;
}
.menu-color-cell-menu-item-selected.active:after {
  display: none;
}
