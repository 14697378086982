/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.list-footer {
  padding: 10px;
  color: #6b7280;
  display: flex;
  justify-content: center;
}
.loader-progress {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #6b7280;
  padding: 8px;
}
.loader-progress span {
  margin-left: 8px;
}
[dir=rtl] .loader-progress span {
  margin-left: 0;
  margin-right: 8px;
}
.grid-container {
  width: 100%;
}
.grid-column-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.grid-column-count {
  flex-grow: 0;
  box-sizing: border-box;
}
