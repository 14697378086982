/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
[data-simplebar] {
  position: relative;
  width: 100%;
  height: 100%;
}
[data-simplebar] .simplebar-offset,
[data-simplebar] .simplebar-content-wrapper,
[data-simplebar] .simplebar-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
