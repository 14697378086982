/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.header-message-link {
  width: 100%;
  font-size: 14px;
  border-radius: 0;
  padding: 18px 12px;
  color: #111827;
  display: flex;
  align-items: center;
}
.header-message-link:hover,
.header-message-link:focus {
  color: #111827;
  background-color: transparent;
}
@media screen and (min-width: 768px) {
  .header-message-link {
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: #fff;
    color: #6b7280;
    padding: 9px;
    border-radius: 50%;
    border: solid 1px transparent;
    margin-left: 7.4px;
    margin-right: 7.4px;
    line-height: 1;
  }
  .header-message-link:hover,
  .header-message-link:focus {
    color: #111827;
    background-color: #ffffff;
    border-color: #e6e6e6;
  }
}
.header-message-icon {
  position: relative;
  display: none;
}
@media screen and (min-width: 768px) {
  .header-message-icon {
    display: block;
  }
}
.header-message-link-text {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .header-message-link-text {
    display: none;
  }
}
.header-message-scroll-submenu {
  height: 200px;
}
@media screen and (min-width: 1600px) {
  .header-message-scroll-submenu {
    height: 260px;
  }
}
.header-message {
  width: 260px;
  padding: 0;
}
@media screen and (min-width: 576px) {
  .header-message {
    width: 300px;
  }
}
@media screen and (min-width: 1600px) {
  .header-message {
    width: 380px;
  }
}
.header-message .ant-dropdown-menu-item {
  padding: 0;
  white-space: normal;
}
.header-message .ant-dropdown-menu-item:hover {
  background-color: transparent;
}
.header-message .ant-dropdown-menu-item.header {
  padding: 12px 20px;
  font-weight: 500;
  cursor: inherit;
}
.header-message .ant-dropdown-menu-item.header:hover,
.header-message .ant-dropdown-menu-item.header:focus {
  background-color: transparent;
}
.header-message-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  text-transform: capitalize;
}
