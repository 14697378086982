/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.nav-menu-style {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.nav-menu-style-item {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}
.nav-menu-style-item-cur {
  position: relative;
  cursor: pointer;
}
