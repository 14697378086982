/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.sidebar-setting:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 20px;
  margin-bottom: 20px;
}
@media screen and (min-width: 1600px) {
  .sidebar-setting:not(:last-child) {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}
.sidebar-setting-title {
  margin-bottom: 16px;
}
