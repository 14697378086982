/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.customizer-option {
  position: absolute;
  right: 0;
  top: 85px;
  z-index: 9;
}
[dir=rtl] .customizer-option {
  right: auto;
  left: 0;
}
@media screen and (min-width: 1600px) {
  .customizer-option {
    top: 125px;
  }
}
.customizer-btn {
  border-radius: 30px 0 0 30px;
  background-color: #ffa940;
  color: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 0 none;
  height: 48px;
  width: 48px;
  padding: 12px;
}
[dir=rtl] .customizer-btn {
  border-radius: 0 30px 30px 0;
}
.customizer-btn:hover,
.customizer-btn:focus {
  background-color: #fa8c16;
  color: #fff;
  border-radius: 30px 0 0 30px;
}
[dir=rtl] .customizer-btn:hover,
[dir=rtl] .customizer-btn:focus {
  border-radius: 0 30px 30px 0;
}
.customizer-btn i {
  font-size: 24px;
}
.customize-drawer .ant-drawer-content-wrapper {
  width: 300px !important;
}
@media screen and (min-width: 1600px) {
  .customize-drawer .ant-drawer-content-wrapper {
    width: 400px !important;
  }
}
.customize-drawer .ant-drawer-body {
  padding: 0;
}
.customize-header {
  padding: 20px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
@media screen and (min-width: 1600px) {
  .customize-header {
    padding: 28px 22px;
  }
}
.customize-header h3 {
  margin-bottom: 2px;
  font-size: 18px;
}
.customize-header p {
  margin-bottom: 0;
  color: #6b7280;
}
.customize-main {
  padding: 20px;
}
@media screen and (min-width: 1600px) {
  .customize-main {
    padding: 28px 22px;
  }
}
.customize-item {
  position: relative;
}
.customize-item:not(:last-child) {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  padding-bottom: 20px;
  margin-bottom: 20px;
}
@media screen and (min-width: 1600px) {
  .customize-item:not(:last-child) {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}
.customize-item h4 {
  margin-bottom: 8px;
}
@media screen and (min-width: 1600px) {
  .customize-item h4 {
    margin-bottom: 12px;
  }
}
.onToggleSidebar-btn {
  border-color: #3973b9;
  color: #3973b9;
}
.onToggleSidebar-btn:first-child {
  border-left-color: #3973b9;
}
.onToggleSidebar-btn.active,
.onToggleSidebar-btn:hover {
  background-color: #3973b9 !important;
  color: #fff !important;
}
.toggle-btn {
  height: 36px;
  line-height: 34px;
  background-color: #fff;
  color: #3973b9;
  border-color: #3973b9 !important;
  text-transform: uppercase;
}
@media screen and (min-width: 1600px) {
  .toggle-btn {
    height: 48px;
    min-width: 96px;
    line-height: 46px;
  }
}
.toggle-btn:hover,
.toggle-btn:focus {
  background-color: #fff;
  color: #3973b9;
}
.toggle-btn.active {
  background-color: #3973b9;
  color: #fff;
}
.toggle-btn.active:hover,
.toggle-btn.active:focus {
  background-color: #3973b9;
  color: #fff;
}
.customize-color {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin-top: 16px;
}
.color-option-list {
  display: flex;
  flex-wrap: wrap;
  margin: 16px -5px 0;
}
.color-option-list-item {
  padding: 0 5px;
  margin-bottom: 10px;
}
.customize-switch-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.customize-switch-view h4 {
  margin-bottom: 0;
}
.customize-switch {
  position: relative;
}
.customize-nav-option {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.customize-nav-option-item {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}
.customize-nav-option-content {
  position: relative;
  cursor: pointer;
}
.customize-nav-option-content .layout-img {
  width: 72px;
}
.customize-nav-option-right-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #3973b9;
  color: #fff;
}
.custom-color-option {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.12);
  height: 40px;
  width: 50px;
}
.custom-color-option-triangle {
  transform: rotate(45deg);
  margin-top: 10px;
  margin-left: 22px;
  height: 60px;
  width: 60px;
}
[dir=rtl] .custom-color-option-triangle {
  margin-left: 0;
  margin-right: 20px;
}
.custom-color-option-border {
  width: 10px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
[dir=rtl] .custom-color-option-border {
  left: auto;
  right: 0;
}
.custom-color-option-right-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #fff;
  color: #3973b9;
}
.custom-color-switch {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 10px;
}
[dir=rtl] .custom-color-switch {
  margin-right: 0;
  margin-left: 10px;
}
.custom-color-switch-color {
  width: 30px;
  height: 16px;
  background-color: #3973b9;
  border: 1px solid #F5F5F5;
  margin-right: 10px;
}
[dir=rtl] .custom-color-switch-color {
  margin-right: 0;
  margin-left: 10px;
}
@media screen and (min-width: 1600px) {
  .custom-color-switch-color {
    width: 40px;
    height: 26px;
  }
}
.secondary-color-switch-color {
  background-color: #2CBDFB;
}
.sidebar-color-switch-color {
  background-color: #001529;
}
.primary-color-popover {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
[dir=rtl] .primary-color-popover {
  left: auto;
  right: 0;
}
.customize-select-box {
  width: 100%;
}
